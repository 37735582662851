import { fetchUtils } from "react-admin";
import { ACCESS_TOKEN_KEY } from "../providers/authProvider";

export function fetchJson(url: string, options: fetchUtils.Options = {}) {
  options.user = {
    authenticated: true,
    token: "Bearer " + localStorage.getItem(ACCESS_TOKEN_KEY),
  };

  return fetchUtils.fetchJson(url, options);
}
