import React from "react";
import { required, TextInput } from "react-admin";
import { MyCreate } from "../common/components/MyCreate";

export function ProjectCreate(props: any) {
  return (
    <MyCreate {...props}>
      <TextInput source="name" fullWidth validate={required()} />
    </MyCreate>
  );
}
