import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import { TeamsFilter } from "./TeamsFilter";

export const TeamList = (props: any) => (
  <List {...props} bulkActionButtons={false} filters={<TeamsFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="type" />
      <EditButton />
    </Datagrid>
  </List>
);
