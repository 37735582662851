import React, { ReactElement } from "react";
import {
  Create,
  CreateProps,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";

type Props = CreateProps & { children: ReactElement } & { toolbar: any };

function CreateToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="list" />
    </Toolbar>
  );
}

export function MyCreate(props: Props) {
  const { children, toolbar, ...rest } = props;
  return (
    <Create {...rest}>
      <SimpleForm variant="outlined" margin="dense" toolbar={<CreateToolbar />}>
        {children}
      </SimpleForm>
    </Create>
  );
}
