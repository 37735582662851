import * as signalr from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { apiUrl } from "../../conf";
import { getToken } from "../../providers/authProvider";

function createConnection(url: string) {
  const token = getToken() || "";
  const options: signalr.IHttpConnectionOptions = {
    transport: signalr.HttpTransportType.WebSockets,
    accessTokenFactory: function () {
      return token;
    },
  };

  let connection = new signalr.HubConnectionBuilder()
    .withUrl(url, options)
    .withAutomaticReconnect()
    .build();

  return connection;
}

export function useHub(hubName: string): [signalr.HubConnection | null] {
  const [connection, setConnection] = useState<signalr.HubConnection | null>(
    null
  );

  useEffect(() => {
    function log(...params: any[]) {
      const value = params.join(",");

      console.log(
        `%cuseHub(${hubName}): %c${value}`,
        "padding:5px; font-weight:bold",
        "color:#444"
      );
    }

    log("start");

    const conn = createConnection(`${apiUrl}/hubs/${hubName}`);

    function start() {
      return conn
        .start()
        .then(() => {
          log("connected");
          setConnection(conn);
        })
        .catch(() => {
          log("start failed");
        });
    }

    conn.onclose(() => {
      log("onclose");
      setConnection(null);
    });

    start();

    return () => {
      log("clean hook");
      setConnection(null);

      conn.stop().then(() => {
        log("stopped");
      });
    };
  }, []);

  // user
  // UpdateFeed
  // UpdateComment
  // UpdatePoll

  // moderator
  // SummaryAvailable
  // UpdateComment
  // UpdatePoll
  // UserBanned
  // UserUnbanned
  // UserConnected
  // UserDisconnected

  return [connection];
}
