import React from "react";
import {
  Filter,
  ResourceComponentProps,
  SelectInput,
  TextInput,
} from "react-admin";

export function EventsFilter(props: ResourceComponentProps) {
  return (
    <Filter {...props}>
      <SelectInput
        source="status"
        alwaysOn
        allowEmpty={false}
        choices={[
          { id: "all", name: "All events" },
          { id: "past", name: "Past" },
          { id: "active", name: "Active" },
          { id: "future", name: "Future" },
        ]}
      />

      <TextInput label="Search" source="q" alwaysOn />

      {/* <ReferenceInput label="Sport" source="userId" reference="users" allowEmpty>
      <SelectInput optionText="name" />
      </ReferenceInput>
      <AutocompleteInput
      source="category"
      choices={[
          { id: "programming", name: "Programming" },
          { id: "lifestyle", name: "Lifestyle" },
          { id: "photography", name: "Photography" },
        ]}
    /> */}
    </Filter>
  );
}
