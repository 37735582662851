export const Resources = {
  Events: "events",
  Sports: "sports",
  Teams: "teams",
  Badges: "badges",
  Users: "users",
  Roles: "roles",
  Projects: "projects",
  Invitations: "invitations"
};
