import { makeStyles, Theme } from "@material-ui/core";

// our override
export const usePunditStyles = makeStyles({
  title: {
    fontSize: '2rem'
  },
  icon: {
    background: "url('./logoBlue.png') ",
    backgroundSize: "cover",
    width: "100px",
    height: "100px",
    margin: "40px",

    "& svg": { display: "none" },
  },
});
// login
export const useLoginStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
  },
  card: {
    minWidth: 440,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    // @ts-ignore
    backgroundColor: theme.palette.secondary[500],
  },
}));
// loginform
export const useLoginFormStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: "0 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  input: {
    marginTop: "1em",
    width: "75%",
  },
  button: {
    width: "75%",
    marginTop: "20px",
    marginBottom: "20px"
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));