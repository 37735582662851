import React from "react";
import {
  CardHeader as CardHeaderUI,
  CardHeaderProps,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#ddd",
    padding: theme.spacing(2),
  },
}));

export function CardHeader(props: CardHeaderProps) {
  const classes = useStyles();

  return <CardHeaderUI className={classes.header} {...props} />;
}
