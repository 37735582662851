import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { IEvent } from "../types";

interface IProps {
  id: string;
  events: IEvent[];
}

export function RoomSelector({ id, events }: IProps) {
  const history = useHistory();

  function handleChange(newId: any) {
    history.push(`/moderator/${newId}`);
    return;
  }

  const items = events;

  return (
    <FormControl variant="outlined" size="small" fullWidth >
      <InputLabel id="selectEvent" shrink>Event</InputLabel>
      <Select
        labelId="selectEvent"
        value={id || ""}
        labelWidth={50}
        displayEmpty
        onChange={(e: any) => handleChange(e.target.value)}
      >
        <MenuItem value="" disabled>
          Choose event ...
        </MenuItem>
        {items.map((x: any) => (
          <MenuItem key={x.id} value={x.id}>
            {x.title} - {x.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
