import React from "react";
import { Card, CardContent, List, makeStyles } from "@material-ui/core";
import { IComment } from "../../common/types";
import { MessageListItem } from "./MessageListItem";
import { CardHeader } from "./CardHeader";

interface IProps {
  comments: IComment[];
  canPush: boolean;
}

const useStyles = makeStyles({
  content: {
    padding: 0,
    maxHeight: 500,
    overflow: "auto",
  },
});

export function MessageList({ comments, canPush }: IProps) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Comments" />
      <CardContent className={classes.content}>
        <List>
          {comments.map((comment, i) => (
            <MessageListItem
              key={comment.id}
              data={comment}
              canPush={canPush}
              divider={i !== comments.length - 1}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
