import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  InputAdornment,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { TextField } from "mui-rff";
import arrayMutators from "final-form-arrays";
import * as yup from "yup";
import { setIn, FormApi } from "final-form";
import { IPoll } from "../../common/types";
import { ModeratorContext } from "../useModerator";

interface FormValues {
  question: string;
  seconds: number;
  options: string[];
}

const validationSchema = yup.object({
  question: yup.string().required(),
  seconds: yup.number().required(),
  options: yup.array().of(yup.string().required()),
});

const validateFormValues = (schema: any) => async (values: any) => {
  if (typeof schema === "function") {
    schema = schema();
  }

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
    return errors;
  }
};

const validate = validateFormValues(validationSchema);

interface Props {
  roomId: string;
}
export function CreatePoll({ roomId }: Props) {
  const { api } = useContext(ModeratorContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (
    { question, options, seconds }: FormValues,
    form: FormApi<FormValues>
  ) => {
    form.pauseValidation();

    try {
      await api.createPoll({
        roomId,
        question,
        lifeSpanSeconds: seconds,
        alternatives: options,
      });

      setOpen(false);
    } catch (err) {
      form.resumeValidation();
      // todo: fix errors
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        New poll
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        disableBackdropClick
      >
        <Form
          onSubmit={handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          validate={validate}
          initialValues={{
            question: "",
            seconds: 1000,
            options: [],
          }}
        >
          {({ handleSubmit, values }) => (
            <>
              <DialogTitle id="form-dialog-title">Create poll</DialogTitle>
              <DialogContent>
                <>
                  <Box display="flex" mb={2}>
                    <Box width="100%" mr={2}>
                      <TextField
                        name="question"
                        autoFocus
                        label="Enter question here"
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Box>
                    <Box width={150} mr={1}>
                      <TextField
                        name="seconds"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">sec</InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <FieldArray name="options">
                    {({ fields }) => (
                      <>
                        {fields.map((name, index) => (
                          <Box key={index} display="flex" alignItems="center">
                            <Box width="100%" mr={2}>
                              <TextField
                                name={name}
                                label="Add option"
                                fullWidth
                                autoFocus
                              />
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                disableElevation
                                onClick={() => fields.remove(index)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        ))}

                        <Button
                          onClick={() => fields.push("")}
                          startIcon={<AddIcon />}
                        >
                          Add option
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  disabled={values.options.length < 2}
                >
                  Create
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </div>
  );
}
