import { TextField } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { ModeratorContext } from "../useModerator";

export function PushUrl(props: { url?: string }) {
  const { api } = useContext(ModeratorContext);
  const [url, setUrl] = useState(props.url || "");

  const update = useCallback(
    debounce((url) => api.changePushUrl(url), 1000),
    []
  );

  function changeUrl(url: string) {
    setUrl(url);
    update(url);
  }

  return (
    <div>
      <TextField
        label="Push URL for 3rd party"
        value={url}
        onChange={(e) => changeUrl(e.target.value)}
        variant="outlined"
        fullWidth
      />
    </div>
  );
}
