import React from "react";
import { DateTime } from "luxon";
import { Tooltip } from "@material-ui/core";

export function DateTimeField(props: any) {
  const value = props.record[props.source];
  
  const short = DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
  const formattedDate = DateTime.fromISO(value).toLocaleString(
    DateTime.DATETIME_SHORT
  );

  return (
    <Tooltip title={formattedDate} aria-label={formattedDate} arrow>
      <div>{short}</div>
    </Tooltip>
  );
}
