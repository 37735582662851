import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

function Image(props: any) {
  if (!props) {
    return null;
  }

  const value = props.record[props.source] as string;
  if (!value || !value.startsWith('data:')) {
    return <div>missing</div>;
  }

  return <img src={`${value}`} alt="" height="50" />;
}

export const BadgeList = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid >
      <TextField source="name.default" sortable={false} />
      <Image source="image" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
