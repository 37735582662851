import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
  Snackbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LockIcon from "@material-ui/icons/Lock";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { fetchUtils } from "react-admin";
import { theme } from "../theme";
import { apiUrl } from "../conf";
import { usePunditStyles, useLoginStyles, useLoginFormStyles } from "../common/styles/loginStyles";

interface FormData {
  email: string;
}

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

type SnackbarMessageType = "success" | "error" | "warning" | "info"

export const LostPasswordPage = (props: any) => {
  const punditClasses = usePunditStyles();
  const loginClasses = useLoginStyles(theme);
  const loginFormClasses = useLoginFormStyles(theme);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // for some reason, React Admin's `notify` doesn't work here
  // not its original hook, not dispatch(showNotification(...)) either
  // so we show the Snackbar with Alert manually
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [snackbarMessageType, setSnackbarMessageType] = useState<SnackbarMessageType>("success");
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const handleSnackbarClose = () => {
    setSnackbarOpened(false);
    setSnackbarMessage("");
  }

  const showSnackbar = (message: string, type: SnackbarMessageType) => {
    setSnackbarOpened(true);
    setSnackbarMessageType(type);
    setSnackbarMessage(message);
  }
  
  const validate = (values: FormData) => {
    const errors: any = {
      email: undefined,
    };

    if (!values.email) {
      errors.email = "Field is required";
    }
    return errors;
  };

  const submit = (values: FormData) => {
    setLoading(true);
    // if we want basically a dataProvider call to a different endpoint without Bearer token
    fetchUtils.fetchJson(`${apiUrl}/api/account/requestPasswordReset/${values.email}`, { method: 'POST' })
      .then(() => {
        setLoading(false);
        setEmailSent(true);
      })
      .catch(() => {
        setLoading(false);
        showSnackbar("User with given email doesn't exist.", "error");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={loginClasses.main}>
        <Card className={loginClasses.card}>
          <div className={loginClasses.avatar}>
            <Avatar className={`${loginClasses.icon} ${punditClasses.icon}`}>
              <LockIcon />
            </Avatar>
          </div>
          <Grid container justify="center">
            <Typography variant="h1" className={punditClasses.title}>Lost password</Typography>
          </Grid>
          { emailSent ? (
            <Alert severity="success" style={{ margin: '20px' }}>
              Email to reset your password was sent successfully.
            </Alert>
          ) : (
            <Form
              onSubmit={submit}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className={loginFormClasses.form}>
                    <div className={loginFormClasses.input}>
                      <Field
                        autoFocus
                        id="email"
                        name="email"
                        type="email"
                        component={Input}
                        label="Email"
                        disabled={loading}
                      />
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={loginFormClasses.button}
                    >
                      {loading && (
                        <CircularProgress
                          className={loginFormClasses.icon}
                          size={18}
                          thickness={2}
                        />
                      )}
                      Send recovery email
                    </Button>
                  </div>
                </form>
              )}
            />
          )}
        </Card>
      </div>
      <Snackbar open={snackbarOpened} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarMessageType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
