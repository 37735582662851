import polyglotI18nProvider from "ra-i18n-polyglot";
import english from "ra-language-english";
// @ts-ignore
import norwegian from "ra-language-norwegian";

const englishResources = {
  ...english,
  resources: {
    events: {
      team: 'Choose team',
      name: "Event |||| Events",
      fields: {
        startTimeUtc: "Start time",
        endTimeUtc: "End time",
        "rooms[0].channel": "Channel",
        "rooms[0].locale": "Language",
      },
    },
    badges: {
      fields: {
        "name.default": "Default name",
        "name.en": "English name",
        "image": "Image",
        "key.type": "Type",
        "key.subType": "Subtype",
      },
    },
  },
};
const messages = {
  en: englishResources,
  no: norwegian,
};
export const i18nProvider = polyglotI18nProvider(
  //@ts-ignore
  (locale) => messages[locale],
  "en"
);
