import React from "react";
import { ReferenceInput, required, SelectInput, TextInput } from "react-admin";
import { MyCreate } from "../common/components/MyCreate";
import { Resources } from "../resources";

export const TeamCreate = (props: any) => (
  <MyCreate {...props}>
    <TextInput source="name" validate={required()} fullWidth />
    <ReferenceInput source="type" reference={Resources.Sports}>
      <SelectInput source="name" validate={required()} />
    </ReferenceInput>
  </MyCreate>
);
