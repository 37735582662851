import { createContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useHub } from "../common/hooks/useHub";
import {
  IComment,
  IBannedUser,
  IPoll,
  IUserScore,
  ICreatePollRequest,
} from "../common/types";
import {
  banUser,
  createPoll,
  deleteComment,
  deleteFeed,
  deletePoll,
  getActiveEvents,
  getBannedUsers,
  getComments,
  getPolls,
  getScoreboard,
  publishPoll,
  pushComment,
  pushFeed,
  pushPoll,
  sendComment,
  unbanUser,
} from "../common/api";
import sortBy from "lodash/sortBy";
import { useAuthenticated, useLogout, useNotify } from "react-admin";

type Actions =
  | { type: "START_LOADING" }
  | { type: "STOP_LOADING" }
  | { type: "START_RECONNECTING" }
  | { type: "STOP_RECONNECTING" }
  | { type: "FEED_UPDATED"; data: any }
  | { type: "COMMENT_UPDATED"; data: any }
  | { type: "INIT_DATA"; data: Partial<State> }
  | { type: "ROOM_SELECTED"; roomId: string }
  | { type: "BANNED_UPDATED"; users: IBannedUser[] }
  | { type: "COMMENT_DELETED"; commentId: string }
  | { type: "POLLS_UPDATED"; polls: IPoll[] }
  | { type: "FEED_DELETED"; id: string }
  | {
      type: "PUSH_URL_CHANGED";
      url: string;
    };

interface State {
  roomId: string;
  eventId: string;
  pushUrl: string;
  currentEvent: any;
  events: any[];
  comments: IComment[];
  activePolls: IPoll[];
  unpublishedPolls: IPoll[];
  scoreboard: IUserScore[];
  ban: IBannedUser[];
  loading: boolean;
  reconecting: boolean;
}

function initState(eventId: string): State {
  return {
    eventId,
    roomId: "",
    pushUrl: "",
    currentEvent: null,
    events: [],
    comments: [],
    activePolls: [],
    unpublishedPolls: [],
    scoreboard: [],
    ban: [],
    loading: false,
    reconecting: false,
  };
}

function reducer(state: State, action: Actions): State {
  console.log(
    `%cmoderatorAction(${action.type}): %O`,
    "padding:5px; font-weight: bold;",
    action
  );

  switch (action.type) {
    case "INIT_DATA": {
      return {
        ...state,
        ...action.data,
      };
    }

    case "START_LOADING": {
      return { ...state, loading: true };
    }
    case "STOP_LOADING": {
      return { ...state, loading: false };
    }

    case "START_RECONNECTING": {
      return { ...state, reconecting: true };
    }
    case "STOP_RECONNECTING": {
      return { ...state, reconecting: false };
    }

    case "COMMENT_UPDATED":
    case "FEED_UPDATED": {
      const comments = [...state.comments];

      const index = comments.findIndex((x) => x.id === action.data.id);
      if (index >= 0) {
        comments[index] = action.data;
      } else {
        comments.push(action.data);
      }

      return { ...state, comments: sortBy(comments, "time").reverse() };
    }

    case "BANNED_UPDATED": {
      return { ...state, ban: action.users };
    }

    case "ROOM_SELECTED": {
      return { ...state, roomId: action.roomId };
    }

    case "COMMENT_DELETED": {
      const comments = [...state.comments];
      const index = comments.findIndex((x) => x.id === action.commentId);
      const comment = comments[index];
      comments[index] = { ...comment, removed: true };

      return { ...state, comments };
    }

    case "FEED_DELETED": {
      const comments = [...state.comments];
      const index = comments.findIndex((x) => x.id === action.id);
      const comment = comments[index];
      comments[index] = { ...comment, removed: true };

      return { ...state, comments };
    }

    case "POLLS_UPDATED": {
      const { polls } = action;

      const active = polls.filter((x: any) => x.publishedUtc);
      const unpublished = polls.filter((x: any) => !x.publishedUtc);

      return {
        ...state,
        activePolls: active,
        unpublishedPolls: unpublished,
      };
    }

    case "PUSH_URL_CHANGED": {
      return { ...state, pushUrl: action.url };
    }

    default: {
      assertUnreachable(action);
    }
  }
}

function assertUnreachable(action: never): never {
  throw new Error(`Unsupported action: ${JSON.stringify(action)}`);
}

function mapInitData(
  roomId: string,
  eventId: string,
  events: any[],
  [comments, polls, score, ban]: [
    IComment[],
    IPoll[],
    IUserScore[],
    IBannedUser[]
  ]
): Partial<State> {
  const active = polls.filter((x: any) => x.publishedUtc);
  const unpublished = polls.filter((x: any) => !x.publishedUtc);
  const currentEvent = events.find((x) => x.id === eventId);

  return {
    eventId,
    currentEvent,
    events,
    roomId,
    comments,
    activePolls: active,
    unpublishedPolls: unpublished,
    scoreboard: score,
    ban,
  };
}

const events = {
  UpdatePoll: "UpdatePoll",
  UpdateComment: "UpdateComment",
  UserConnected: "UserConnected",
  UserDisconnected: "UserDisconnected",
};

export function useModerator() {
  useAuthenticated();

  let { id: eventId } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(reducer, initState(eventId));

  const [connection] = useHub("moderator");

  const notify = useNotify();
  const logout = useLogout();

  const { roomId } = state;

  useEffect(() => {
    // moderator
    if (connection?.state === "Connected" && roomId) {
      connection.onreconnecting(() => {
        dispatch({ type: "START_RECONNECTING" });
      });

      connection.onreconnected(() => {
        dispatch({ type: "STOP_RECONNECTING" });
      });

      connection.on(events.UpdateComment, (data) => {
        const comment = { ...data, type: "UserComment" };

        dispatch({ type: "COMMENT_UPDATED", data: comment });
      });

      connection.on(events.UpdatePoll, () => {
        updatePolls(roomId);
      });

      connection.on(events.UserConnected, () => {
        notify("user connected", "info");
      });
      connection.on(events.UserDisconnected, () => {
        notify("user disconnected", "warning");
      });

      connection
        .invoke("SubscribeToRoom", roomId)
        .catch(() => notify("failed to subscribe to room"));

      return () => {
        connection.off(events.UpdateComment);
        connection.off(events.UpdatePoll);
        connection.off(events.UserConnected);
        connection.off(events.UserDisconnected);
      };
    }
  }, [connection, roomId]);

  useEffect(() => {
    async function load() {
      dispatch({ type: "START_LOADING" });

      try {
        const events = await getActiveEvents();
        const roomId = getRooomId(events, eventId);

        if (!roomId) {
          dispatch({
            type: "INIT_DATA",
            data: { events },
          });
          dispatch({ type: "STOP_LOADING" });
          return;
        }

        const results = await Promise.all([
          getComments(roomId),
          getPolls(roomId),
          getScoreboard(roomId),
          getBannedUsers(roomId),
        ]);

        const initData = mapInitData(roomId, eventId, events, results);

        dispatch({
          type: "INIT_DATA",
          data: initData,
        });
      } catch (err) {
        logout();
      }
      dispatch({ type: "STOP_LOADING" });
    }

    load();
  }, [eventId]);

  async function updateBannedUsers() {
    const users = await getBannedUsers(roomId);

    dispatch({ type: "BANNED_UPDATED", users });
  }

  async function updatePolls(roomId: string) {
    const polls = await getPolls(roomId);

    dispatch({ type: "POLLS_UPDATED", polls });
  }

  return {
    state,
    api: {
      async banUser(userId: string) {
        dispatch({ type: "START_LOADING" });

        await banUser(roomId, userId);
        await updateBannedUsers();

        dispatch({ type: "STOP_LOADING" });
      },
      async unbanUser(userId: string) {
        dispatch({ type: "START_LOADING" });

        await unbanUser(roomId, userId);
        await updateBannedUsers();

        dispatch({ type: "STOP_LOADING" });
      },

      async sendComment(text: string) {
        dispatch({ type: "START_LOADING" });
        try {
          const feed = await sendComment(roomId, text);
          dispatch({
            type: "FEED_UPDATED",
            data: { ...feed, type: "ModeratorComment" },
          });
        } catch (err) {
          notify("Failed to send comment", "error");
        }
        dispatch({ type: "STOP_LOADING" });
      },
      async deleteComment(id: string) {
        try {
          await deleteComment(id);
          dispatch({ type: "COMMENT_DELETED", commentId: id });
        } catch (err) {
          notify("Failed to delete comment", "error");
        }
      },
      async deleteFeed(id: string) {
        await deleteFeed(id);
        dispatch({ type: "FEED_DELETED", id });
      },

      async deletePoll(id: string) {
        await deletePoll(id);
        await updatePolls(roomId);
      },
      async publishPoll(id: string) {
        await publishPoll(id);
        await updatePolls(roomId);
      },
      async createPoll(data: ICreatePollRequest) {
        await createPoll(data);
        await updatePolls(roomId);
      },

      async pushFeed(id: string) {
        try {
          await pushFeed(id, state.pushUrl);
        } catch (err) {
          notify("Push feed failed", "error");
        }
      },
      async pushComment(id: string) {
        try {
          await pushComment(id, state.pushUrl);
        } catch (err) {
          notify("Push comment failed", "error");
        }
      },
      async pushPoll(id: string) {
        try {
          await pushPoll(id, state.pushUrl);
        } catch (err) {
          console.log(err);
          notify("Push poll failed", "error");
        }
      },

      async changePushUrl(url: string) {
        dispatch({ type: "PUSH_URL_CHANGED", url });
      },
    },
  };
}

function getRooomId(events: any[], eventId: string) {
  if (!eventId) {
    return null;
  }
  return events.find((x: any) => x.id === eventId).rooms[0].id;
}

type ModeratorInterface = ReturnType<typeof useModerator>;

//@ts-ignore
export const ModeratorContext = createContext<ModeratorInterface>({});
