import React from "react";
import { Layout } from "react-admin";
import { MyMenu } from "./MyMenu";

export function MyLayout(props: any) {
  return (
    <Layout {...props} menu={MyMenu}>
      {props.children}
    </Layout>
  );
}
