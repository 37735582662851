import React from "react";
import { InputProps, required } from "react-admin";
import { Grid, Box } from "@material-ui/core";
import { DateTimePicker } from "../../common/components/DateTimePicker";
export function Published(props: Omit<InputProps, "source">) {
  const { className, ...rest } = props;

  return (
    <Box pb={2}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <DateTimePicker
            {...rest}
            source="startTimeUtc"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <DateTimePicker
            {...rest}
            validate={required()}
            source="endTimeUtc"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
