import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

function Dashboard() {
  return (
    <Card>
      <CardHeader title="Dashboard" subheader="statistics overview" />
      <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>
  );
}

export default Dashboard;
