import React, { useMemo } from "react";
// @ts-ignore
import { useSelector } from "react-redux";
// @ts-ignore
import inflection from "inflection";
import { useMediaQuery } from "@material-ui/core";
import {
  MenuItemLink,
  getResources,
  useTranslate,
  Translate,
  DashboardMenuItem,
  usePermissionsOptimized,
} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import LabelIcon from "@material-ui/icons/Label";
import { Resources } from "../../resources";
import { roles } from "../../roles";

const translatedResourceName = (resource: any, translate: Translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const Moderator = "moderator";

const allRoutes = [...Object.values(Resources), Moderator];

function getRoutesByRole(role: string) {
  switch (role) {
    case roles.Admin:
      return allRoutes;
    case roles.Manager:
      return [Resources.Events, Resources.Users];
    case roles.Moderator:
      return [Moderator];
    default: {
      return [];
    }
  }
}

function routesFilter(role: string) {
  return (resource: { name: string }) => {
    return getRoutesByRole(role).includes(resource.name);
  };
}

export const MyMenu = (props: any) => {
  const { permissions } = usePermissionsOptimized();
  const allResources = useSelector(getResources);

  const resources = allResources.filter((resource: any) => resource.name !== Resources.Invitations);

  const canRender = !!permissions?.role && resources?.length > 0;

  return canRender ? (
    <MyMenuInner {...props} role={permissions.role} resources={resources} />
  ) : null;
};

export const MyMenuInner = (props: any) => {
  const { onMenuClick, logout, hasDashboard, role, resources } = props;

  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  const links = useMemo(() => {
    const allResources = [...resources, { name: Moderator, icon: LabelIcon }];
    const filtered = allResources.filter(routesFilter(role));

    return filtered;
  }, [role, resources]);

  return (
    <div>
      {hasDashboard && (
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      )}

      {links.map((resource: any) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translatedResourceName(resource, translate)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}

      {isXSmall && logout}
    </div>
  );
};
