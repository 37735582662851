import { createMuiTheme } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#70f170",
      main: "#4faa5e",
      dark: "#096b1e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      margin: "dense",
    },
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: lightBlue[500],
        color: "#fff",
      },
    },
  },
});
