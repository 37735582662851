import React from "react";
import { IEvent } from "../types";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { formatDateTime } from "../../common/format";

interface IProps {
  data: IEvent;
}

function LinearProgressWithLabel(props: any) {
  return (
    <Box display="flex" alignItems="center" p={2}>
      <Box width={200} textAlign="right">
        <Typography variant="body2" color="textSecondary">
          Event progress:&nbsp;{`${Math.round(props.value)}%`}
        </Typography>
      </Box>
      <Box width="100%" ml={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

export function EventProgress({ data }: IProps) {
  if (!data) {
    return null;
  }

  const start = formatDateTime(data.startTimeUtc);
  const end = formatDateTime(data.endTimeUtc);

  const full = +new Date(data.endTimeUtc) - +new Date(data.startTimeUtc);
  const part = +new Date() - +new Date(data.startTimeUtc);
  const progress = (part / full) * 100;

  return (
    <Box display="flex" flexWrap="wrap" mb={1}>
      <Box width="100%" flexBasis="100%" mb={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box flexBasis="50%">{start}</Box>
      <Box flexBasis="50%" textAlign="right">
        {end}
      </Box>
    </Box>
  );
}
