import React from "react";
import { ReferenceInput, SelectInput, TextInput } from "react-admin";
import { MyEdit } from "../common/components/MyEdit";
import { Resources } from "../resources";

export const TeamEdit = (props: any) => (
  <MyEdit {...props}>
    <TextInput source="name" fullWidth />
    <ReferenceInput source="type" reference={Resources.Sports}>
      <SelectInput source="name" />
    </ReferenceInput>
  </MyEdit>
);
