import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LockIcon from "@material-ui/icons/Lock";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { fetchUtils, useLogin } from "react-admin";
import { theme } from "../theme";
import { apiUrl } from "../conf";
import {
  usePunditStyles,
  useLoginStyles,
  useLoginFormStyles,
} from "../common/styles/loginStyles";

interface FormData {
  name: string;
  username: string;
  password: string;
}

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

type SnackbarMessageType = "success" | "error" | "warning" | "info";

export const RegistrationPage = (props: any) => {
  const punditClasses = usePunditStyles();
  const loginClasses = useLoginStyles(theme);
  const loginFormClasses = useLoginFormStyles(theme);
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  // from URL
  const invitationHash = props.match.params.hash;

  // for some reason, React Admin's `notify` doesn't work here
  // not its original hook, not dispatch(showNotification(...)) either
  // so we show the Snackbar with Alert manually
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [
    snackbarMessageType,
    setSnackbarMessageType,
  ] = useState<SnackbarMessageType>("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpened(false);
    setSnackbarMessage("");
  };

  const showSnackbar = (message: string, type: SnackbarMessageType) => {
    setSnackbarOpened(true);
    setSnackbarMessageType(type);
    setSnackbarMessage(message);
  };

  const validate = (values: FormData) => {
    const errors: any = {
      name: undefined,
      password: undefined,
    };

    if (!values.name) {
      errors.name = "Field is required";
    }

    if (!values.password) {
      errors.password = "Field is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    return errors;
  };

  const submit = (values: FormData) => {
    setLoading(true);
    // if we want basically a dataProvider call to a different endpoint without Bearer token
    fetchUtils
      .fetchJson(`${apiUrl}/api/account/register`, {
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          password: values.password,
          invitationHash,
        }),
      })
      .then((data) => ({ data: data.json }))
      .then(({ data: { username } }) => {
        login({ username, password: values.password });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMsg =
          typeof error.body === "string" ? error.body : "Something went wrong";

        showSnackbar(errorMsg, "error");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={loginClasses.main}>
        <Card className={loginClasses.card}>
          <div className={loginClasses.avatar}>
            <Avatar className={`${loginClasses.icon} ${punditClasses.icon}`}>
              <LockIcon />
            </Avatar>
          </div>
          <Grid container justify="center">
            <Typography variant="h1" className={punditClasses.title}>
              Register
            </Typography>
          </Grid>
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={loginFormClasses.form}>
                  <div className={loginFormClasses.input}>
                    <Field
                      autoFocus
                      id="name"
                      name="name"
                      component={Input}
                      label="Name"
                      disabled={loading}
                    />
                  </div>
                  <div className={loginFormClasses.input}>
                    <Field
                      id="password"
                      name="password"
                      component={Input}
                      label="Password"
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={loginFormClasses.button}
                  >
                    {loading && (
                      <CircularProgress
                        className={loginFormClasses.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    Register
                  </Button>
                </div>
              </form>
            )}
          />
        </Card>
      </div>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessageType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
