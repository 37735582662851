import React, { useCallback } from "react";
import {
  Button,
  TextField,
  TextInput,
  useInput,
  useTranslate,
} from "react-admin";
import { MyEdit } from "../common/components/MyEdit";
import { useDropzone } from "react-dropzone";
import { makeStyles, IconButton } from "@material-ui/core";
import HighlightOff from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => {
  console.log(theme);
  return {
    wrapper: {
      // border: "#ddd 1px solid",
      // borderRadius: "3px",
      // padding: theme.spacing(1),
    },
    dropzone: {
      padding: theme.spacing(1),
      border: "1px dashed",
      borderColor: theme.palette.grey[500],
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
    },
    imageWrap: {
      position: "relative",
      padding: theme.spacing(2),
    },
    image: {
      height: "100px",
    },
    removeButton: {
      position: "absolute",
      top: 0,
    },
  };
});

function MyDropzone(props: any) {
  const {
    input: { value, onChange },
  } = useInput(props);
  const classes = useStyles();
  const translate = useTranslate();

  const onDrop = useCallback(
    (acceptedFiles) => {
      toBase64(acceptedFiles[0]).then((x) => {
        onChange({ target: { value: x } });
      });
    },
    [onChange]
  );
  const label = translate(`resources.${props.resource}.fields.${props.source}`);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classes.wrapper}>
      <div>{label}</div>
      <div {...getRootProps()} className={classes.dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {value && (
        <div className={classes.imageWrap}>
          <img src={value} alt="" className={classes.image} />
          <IconButton
            className={classes.removeButton}
            aria-label="delete"
            onClick={(e) => {
              e.preventDefault();
              onChange(null);
            }}
          >
            <HighlightOff />
          </IconButton>
        </div>
      )}
    </div>
  );
}

function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function BadgeEdit(props: any) {
  return (
    <MyEdit {...props}>
      <TextInput source="name.default" fullWidth disabled />

      <TextInput source="name.en" fullWidth />
      <TextInput source="name.no" fullWidth />

      <MyDropzone source="image" />
    </MyEdit>
  );
}
