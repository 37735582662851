import { AuthProvider, UserIdentity } from "react-admin";
import jwt_decode from "jwt-decode";
import { canLogin, roles } from "../roles";

export const ACCESS_TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh";

type Func<P, T> = (params: P) => T;

const provider: Func<string, AuthProvider> = (apiUrl) => {
  return {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("username", username);
      urlencoded.append("password", password);
      urlencoded.append("grant_type", "password");
      urlencoded.append("scope", "openid email profile offline_access roles");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
      };

      const response = await fetch(`${apiUrl}/connect/token`, requestOptions);

      if (response.status !== 200) {
        throw new Error("Login failed");
      }

      const json: {
        access_token: string;
        expires_in: number;
        id_token: string;
        refresh_token: string;
        token_type: "Bearer";
      } = await response.json();

      if (!json.access_token || !json.refresh_token) {
        throw new Error("missing tokens");
      }

      const { role } = parseToken(json.access_token);
      if (!canLogin(role)) {
        throw new Error("insufficient role");
      }

      localStorage.setItem(ACCESS_TOKEN_KEY, json.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, json.refresh_token);

      return true;
    },
    // called when the user clicks on the logout button
    logout: () => {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: async ({ status }) => {
      console.log("checkerror", status);

      if (status === 401 || status === 403) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);

        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: (a) => {
      console.log("checkauth", a);
      return localStorage.getItem(ACCESS_TOKEN_KEY)
        ? Promise.resolve()
        : Promise.reject();
    },
    getPermissions: (a) => {
      // according to docs, the format of what this method returns, is free and React Admin doesn't rely on it
      // however, it calls this method every time before navigating to another page and passes the result to the new page
      // => we can set it so that getPermissions either returns { role } or undefined, React Admin doesn't care
      console.log("getPermissions", {a});
      const data = getTokenData();
      if (data) {
        return Promise.resolve({ role: data.role })
      }
      return Promise.resolve();
    },
    getIdentity: () => {
      const data = getTokenData();
      if (!data) {
        return Promise.reject('User is not logged in')
      }
      return Promise.resolve({
        id: data.sub,
        avatar: "",
        fullName: data.name,
      } as UserIdentity);
    },
  };
};

interface TokenData {
  name: string;
  role: string;
  sub: string;
}

export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getTokenData(): TokenData | null {
  const token = getToken();
  if (!token) {
    return null;
  }
  return parseToken(token);
}

function parseToken(token: string): TokenData {
  return jwt_decode(token);
}

// async function refreshLogin(
//   apiUrl: string
// ): Promise<{ access_token: string; refresh_token: string }> {
//   let header = new Headers();
//   header.append("Content-Type", "application/x-www-form-urlencoded");

//   let searchParams = new URLSearchParams();
//   searchParams.append(
//     "refresh_token",
//     localStorage.getItem(REFRESH_TOKEN_KEY) || ""
//   );
//   searchParams.append("grant_type", "refresh_token");
//   searchParams.append("scope", "openid email profile offline_access roles");

//   var requestOptions = {
//     method: "POST",
//     headers: header,
//     body: searchParams,
//   };

//   const response = await fetch(`${apiUrl}/connect/token`, requestOptions);
//   const result = await response.json();

//   return result;
// }

export default provider;
