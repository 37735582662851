import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
  Snackbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LockIcon from "@material-ui/icons/Lock";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { fetchUtils, useLogin } from "react-admin";
import { theme } from "../theme";
import { apiUrl } from "../conf";
import { usePunditStyles, useLoginStyles, useLoginFormStyles } from "../common/styles/loginStyles";

interface FormData {
  password: string;
}

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

type SnackbarMessageType = "success" | "error" | "warning" | "info"

export const ResetPasswordPage = (props: any) => {
  const punditClasses = usePunditStyles();
  const loginClasses = useLoginStyles(theme);
  const loginFormClasses = useLoginFormStyles(theme);
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  // from URL
  const params = new URLSearchParams(props.location.search)

  // for some reason, React Admin's `notify` doesn't work here
  // not its original hook, not dispatch(showNotification(...)) either
  // so we show the Snackbar with Alert manually
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [snackbarMessageType, setSnackbarMessageType] = useState<SnackbarMessageType>("success");
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const handleSnackbarClose = () => {
    setSnackbarOpened(false);
    setSnackbarMessage("");
  }

  const showSnackbar = (message: string, type: SnackbarMessageType) => {
    setSnackbarOpened(true);
    setSnackbarMessageType(type);
    setSnackbarMessage(message);
  }
  
  const validate = (values: FormData) => {
    const errors: any = {
      password: undefined,
    };

    if (!values.password) {
      errors.password = "Field is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    return errors;
  };

  const submit = (values: FormData) => {
    if (!params.has('userId') || !params.has('token')) {
      showSnackbar("Invalid password reset link.", "error");
      return;
    }
    setLoading(true);
    // if we want basically a dataProvider call to a different endpoint without Bearer token
    fetchUtils.fetchJson(`${apiUrl}/api/account/passwordReset`, {
      method: 'POST',
      body: JSON.stringify({
        userId: params.get("userId"),
        token: params.get("token"),
        password: values.password
      })
    }).then((data) => 
      ({ data: data.json })
    ).then(({ data: { username } }) => {
      login({ username, password: values.password });
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      showSnackbar(error.body, "error");
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={loginClasses.main}>
        <Card className={loginClasses.card}>
          <div className={loginClasses.avatar}>
            <Avatar className={`${loginClasses.icon} ${punditClasses.icon}`}>
              <LockIcon />
            </Avatar>
          </div>
          <Grid container justify="center">
            <Typography variant="h1" className={punditClasses.title}>Reset password</Typography>
          </Grid>
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={loginFormClasses.form}>
                  <div className={loginFormClasses.input}>
                    <Field
                      id="password"
                      name="password"
                      component={Input}
                      label="New password"
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={loginFormClasses.button}
                  >
                    {loading && (
                      <CircularProgress
                        className={loginFormClasses.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    Reset password
                  </Button>
                </div>
              </form>
            )}
          />
        </Card>
      </div>
      <Snackbar open={snackbarOpened} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarMessageType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
