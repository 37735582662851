import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import { IUserScore } from "../../common/types";
import { CardHeader } from "./CardHeader";

interface IProps {
  scoreboard: Array<IUserScore>;
}

export function ScoreList({ scoreboard }: IProps) {
  const userCount = scoreboard.length;

  return (
    <Box mb={1}>
    <Card>
      <CardHeader title="Scoreboard" subheader={`${userCount} users`} />
      <CardContent>
        <table>
          <tbody>
            {scoreboard.map((x) => (
              <tr key={x.userId}>
                <th style={{ textAlign: "left" }}>{x.name}</th>
                <td>{x.score} points</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
    </Box>
  );
}
