import sortBy from "lodash/sortBy";
import { apiUrl } from "../conf";
import { fetchJson } from "./httpClient";
import {
  IComment,
  IBannedUser,
  ICreatePollRequest,
  IPoll,
  IUserScore,
  IRoomSummary,
} from "./types";

const API = apiUrl;

export async function banUser(roomId: string, userId: string) {
  return fetchJson(`${API}/api/room/ban/?roomId=${roomId}&userId=${userId}`, {
    method: "POST",
  });
}
export async function unbanUser(roomId: string, userId: string) {
  return fetchJson(`${API}/api/room/ban/?roomId=${roomId}&userId=${userId}`, {
    method: "DELETE",
  });
}

export async function getActiveEvents(): Promise<Array<any>> {
  try {
    const events = await fetchJson(
      `${API}/admin_api/events?filter={status:"active"}`
    );

    return events.json;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to load active events");
  }
}

export async function deleteFeed(id: string) {
  try {
    await fetchJson(`${API}/api/room/feeds/${id}`, {
      method: "DELETE",
    });
  } catch (err) {
    console.error("Failed to delete feed", err);
    throw new Error("Failed to delete feed");
  }
}
export async function deleteComment(id: string) {
  try {
    await fetchJson(`${API}/api/room/comment/${id}`, {
      method: "DELETE",
    });
  } catch (err) {
    console.error("Failed to delete comment", err);
    throw new Error("Failed to delete comment");
  }
}

export async function sendComment(roomId: string, message: string) {
  try {
    const response = await fetchJson(`${API}/api/room/feeds/`, {
      method: "POST",
      body: JSON.stringify({ roomId, text: message }),
    });

    return response.json;
  } catch (err) {
    console.error("Failed to send comment", err);
    throw new Error("Failed to send comment");
  }
}

export async function getBannedUsers(roomId: string): Promise<IBannedUser[]> {
  const result = await fetchJson(`${API}/api/room/ban/${roomId}`);

  return result.json;
}

export async function createPoll(input: ICreatePollRequest): Promise<IPoll> {
  const result = await fetchJson(`${API}/api/poll/`, {
    method: "POST",
    body: JSON.stringify(input),
  });

  return result.json;
}

export async function deletePoll(id: string) {
  try {
    const result = await fetchJson(`${API}/api/poll/${id}`, {
      method: "DELETE",
    });
  } catch (err) {
    throw new Error("Cannot delete poll");
  }
}

export async function publishPoll(id: string) {
  try {
    const result = await fetchJson(`${API}/api/poll/${id}`, {
      method: "PUT",
    });
  } catch (err) {
    throw new Error("Cannot publish poll");
  }
}

async function pushFn({
  id,
  type,
  url,
}: {
  id: string;
  type: 0 | 1 | 2;
  url: string;
}) {
  try {
    const data = {
      FeedId: id,
      FeedType: type,
      URL: url,
    };

    await fetchJson(`${API}/api/Push`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw new Error("Failed to push to 3rd party");
  }
}
export async function pushPoll(id: string, url: string) {
  return pushFn({ id, url, type: 2 });
}
export async function pushFeed(id: string, url: string) {
  return pushFn({ id, url, type: 1 });
}
export async function pushComment(id: string, url: string) {
  return pushFn({ id, url, type: 0 });
}

export async function getPolls(roomId: string): Promise<Array<IPoll>> {
  try {
    const result = await fetchJson(`${API}/api/poll/${roomId}`);

    return result.json as IPoll[];
  } catch (err) {
    throw new Error("Get polls failed");
  }
}

export async function getComments(roomId: string): Promise<Array<IComment>> {
  const [moderator, user] = await Promise.all([
    fetchJson(`${API}/api/room/feeds/${roomId}`),
    fetchJson(`${API}/api/room/extendedComment/${roomId}`),
  ]);

  const f = moderator.json.map((x: any) => ({
    ...x,
    type: "ModeratorComment",
  }));

  const c = user.json.map((x: any) => ({
    ...x,
    type: "UserComment",
  }));

  const comments = sortBy(f.concat(c), "time").reverse();

  return (comments as any) as Array<IComment>;
}

export async function getScoreboard(
  roomId: string
): Promise<Array<IUserScore>> {
  try {
    const result = await fetchJson(`${API}/api/score/list?roomId=${roomId}`);

    return result.json.scoreboard;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to load scoreboard");
  }
}

export function getSummary(roomId: string): Promise<IRoomSummary> {
  return fetchJson(`${API}/api/room/totalSummary/${roomId}`).then(
    (x) => x.json
  );
}

export function generateSummary(roomId: string) {
  return fetchJson(`${API}/api/creator/generateSummary/${roomId}`, {
    method: "PUT",
  });
}
