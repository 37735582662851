import React from "react";
import { TextInput, required, ReferenceInput, SelectInput } from "react-admin";
import { MyEdit } from "../common/components/MyEdit";
import { roles } from "../roles";
import { Published } from "./components/Published";

export function EventEdit(props: any) {
  const role = props.permissions?.role;

  return (
    <MyEdit {...props}>
      <TextInput source="title" fullWidth validate={required()} />
      <TextInput source="description" fullWidth multiline rows="3" />

      {role === roles.Admin && (
        <ReferenceInput source="projectId" reference="projects">
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      <Published />
    </MyEdit>
  );
}
