import React, { useContext } from "react";
import {
  Button,
  List,
  ListItem,
  CardContent,
  makeStyles,
  Card,
  Box,
} from "@material-ui/core";
import { Publish as PublishIcon } from "@material-ui/icons";
import { IPoll } from "../../common/types";
import { ModeratorContext } from "../useModerator";
import { CardHeader } from "./CardHeader";

interface Props {
  data: IPoll[];
}

export function ActivePolls({ data }: Props) {
  return (
    <Box mb={1}>
      <Card>
        <CardHeader title="Active polls" />
        <CardContent>
          <List>
            {data.map((x) => (
              <Item key={x.id} data={x} />
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  answerText: {
    width: "30%",
    minWidth: 100,
  },
  question: {
    marginBottom: theme.spacing(1),
  },
  answers: {
    fontSize: 11,
    marginBottom: theme.spacing(1),
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  endDate: {
    fontSize: "0.8rem",
  },
}));

function Item({ data }: { data: IPoll }) {
  const classes = useStyles();
  const { api } = useContext(ModeratorContext);

  const { id, question, alternatives } = data;

  return (
    <ListItem key={id} divider classes={{ root: classes.listItem }}>
      <strong className={classes.question}>{question}</strong>

      <table className={classes.answers}>
        <tbody>
          {alternatives.map((a) => (
            <tr key={a.id}>
              <th className={classes.answerText}>{a.text}</th>
              <td>{a.answers} votes</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Button
        onClick={() => api.pushPoll(id)}
        size="small"
        startIcon={<PublishIcon />}
      >
        Push
      </Button>
    </ListItem>
  );
}
