import React, { useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import { ModeratorContext } from "../useModerator";
import { CardHeader } from "./CardHeader";

const useStyles = makeStyles((theme) => ({
  answerText: {
    width: "30%",
    minWidth: 100,
  },

  answers: {
    marginBottom: theme.spacing(1),
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardContent: {
    padding: 0,
  },
}));

export function UnpublishedPolls({ data }: any) {
  const classes = useStyles();
  const { api } = useContext(ModeratorContext);

  if (!data) {
    return null;
  }
  return (
    <Card>
      <CardHeader title="Unpublished polls" />
      <CardContent className={classes.cardContent}>
        <List>
          {data.map((x: any) => (
            <ListItem key={x.id} divider classes={{ root: classes.listItem }}>
              <strong>{x.question}</strong>
              <div>{x.lifeSpanSeconds} seconds</div>
              <table className={classes.answers}>
                <tbody>
                  {x.alternatives.map((z: any) => (
                    <tr key={z.id}>
                      <th className={classes.answerText}>{z.text}</th>
                      <td>{z.answers} votes</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Box>
                <Button
                  size="small"
                  onClick={() => api.publishPoll(x.id)}
                  startIcon={<CheckIcon />}
                >
                  Publish
                </Button>
                <Button
                  size="small"
                  onClick={() => api.deletePoll(x.id)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
