import React from "react";
import { Link } from "react-router-dom";
import { Button, ButtonProps } from "@material-ui/core";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

interface Props extends ButtonProps<"a"> {
  roomId: string;
}

export function SummaryButton({ roomId, ...buttonProps }: Props) {
  return (
    <Button
      component={Link}
      color="primary"
      variant="outlined"
      to={`/summary/${roomId}`}
      startIcon={<TrendingUpIcon />}
      {...buttonProps}
    >
      Summary
    </Button>
  );
}
