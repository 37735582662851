import React from "react";
import {
  Card,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  Container,
} from "@material-ui/core";
import { BannedUsers } from "./components/BannedUsers";
import { EventDetails } from "./components/EventDetail";
import { EventProgress } from "./components/EventProgress";
import { MessageList } from "./components/MessageList";
import { RoomSelector } from "./components/RoomSelector";
import { ScoreList } from "./components/ScoreList";
import SendMessage from "./components/SendMessageDialog";

import { CreatePoll } from "./components/CreatePoll";
import { ModeratorContext, useModerator } from "./useModerator";
import { ActivePolls } from "./components/ActivePolls";
import { UnpublishedPolls } from "./components/UnpublishedPolls";
import { PushUrl } from "./components/PushUrl";
import { SummaryButton } from "../common/components/SummaryButton";
import { Title } from "react-admin";

type State = ReturnType<typeof useModerator>["state"];

export function ModeratorView() {
  const moderator = useModerator();

  const { loading, eventId, events } = moderator.state;

  const title = "Moderator";

  return (
    <ModeratorContext.Provider value={moderator}>
      <Title title={title} />

      <Container>
        <Backdrop open={loading} style={{ zIndex: 20 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div>
          <Box mb={2}>
            <Card>
              <Box p={1}>
                <RoomSelector id={eventId} events={events} />
              </Box>
            </Card>
          </Box>

          {eventId && <Content state={moderator.state} />}
        </div>
      </Container>
    </ModeratorContext.Provider>
  );
}

function Content({
  state: {
    currentEvent,
    pushUrl,
    roomId,
    comments,
    activePolls,
    unpublishedPolls,
    scoreboard,
    ban,
  },
}: {
  state: State;
}) {
  return (
    <>
      <Box mb={2}>
        <Card>
          <Box p={1}>
            <EventDetails data={currentEvent} />
            <EventProgress data={currentEvent} />
          </Box>
        </Card>
      </Box>

      <Box mb={2}>
        <Card>
          <Box p={1}>
            <PushUrl url={pushUrl} />
          </Box>
        </Card>
      </Box>

      <Box mb={2}>
        <Card>
          <Box p={2} display="flex">
            <Box mr={1}>
              <SendMessage />
            </Box>
            <Box mr={1}>
              <CreatePoll roomId={roomId} />
            </Box>
            <Box mr={1}>
              <SummaryButton roomId={roomId} />
            </Box>
          </Box>
        </Card>
      </Box>

      <Grid container spacing={1}>
        <Grid item md={4} sm={6} xs={12}>
          <MessageList comments={comments} canPush={!!pushUrl} />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ActivePolls data={activePolls} />
          <UnpublishedPolls data={unpublishedPolls} />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <ScoreList scoreboard={scoreboard} />
          <BannedUsers roomId={roomId} data={ban} />
        </Grid>
      </Grid>
    </>
  );
}
