export * from "./RoleList";

export const roles = {
  Admin: "Admin",
  Manager: "Manager",
  Moderator: "Moderator",
  User: "User",
};

export function canLogin(role: string) {
  return [roles.Admin, roles.Manager, roles.Moderator].includes(role);
}
