import React, { cloneElement, useState } from "react";
import {
  Datagrid,
  EmailField,
  ReferenceField,
  ReferenceArrayField,
  TextField,
  List,
  EditButton,
  ChipField,
  SingleFieldList,
  Button,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from "react-admin";
import AddIcon from '@material-ui/icons/Add';
import { roles } from "../roles";
import { InviteDialog } from "./InviteDialog";

const ListActions = (props: any) => {
  const { className, openInviteDialog, canInvite, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  // replicates implicit TopToolbar but extends it with the invite button
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      {canInvite && (
        <Button
          onClick={() => openInviteDialog()}
          label="Invite a new user"
        >
          <AddIcon />
        </Button>
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};
export function UserList(props: any) {
  const role = props.permissions?.role;
  const canInvite = role && [roles.Admin, roles.Manager].includes(role);
  const [inviteModalShown, setInviteModalShown] = useState(false);
  
  const openInviteDialog = () => {
    setInviteModalShown(true);
  }
  
  const onInviteClose = () => {
    setInviteModalShown(false);
  }
  
  return (
    <>
      <List {...props} actions={<ListActions openInviteDialog={openInviteDialog} canInvite={canInvite} />}>
        <Datagrid>
          <TextField source="userName" />
          <TextField source="name" />
          <EmailField source="email" />

          <ReferenceField
            source="roleId"
            reference="roles"
            sortBy="UserRoles.First().Role.Name"
          >
            <TextField source="name" />
          </ReferenceField>

          {role === roles.Admin && (
            <ReferenceArrayField
              label="Projects"
              reference="projects"
              source="projectIds"
            >
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )}

          <EditButton />
        </Datagrid>
      </List>
      {canInvite && (
        <InviteDialog open={inviteModalShown} onCloseDialog={onInviteClose} role={role} />
      )}
    </>
  );
}
