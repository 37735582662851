import React from "react";
import { useInput, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  adornedEnd: {
    paddingRight: 5,
  },
});

export function DateTimePicker(props: any) {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    input: { name, ...rest },
    meta: { error, touched },
    isRequired,
  } = useInput(props);

  const label =
    translate(`resources.${props.resource}.fields.${props.source}`) +
    (isRequired ? " *" : "");
  const errorText = touched ? translate(error) : "";
  const value = rest.value || null;

  return (
    <KeyboardDateTimePicker
      {...rest}
      label={label}
      value={value}
      inputVariant="outlined"
      format="F"
      margin="dense"
      fullWidth
      InputProps={{ classes: { adornedEnd: classes.adornedEnd } }}
      error={!!(touched && error)}
      helperText={errorText}
    />
  );
}
