import React from "react";
import {
  Filter,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextInput,
} from "react-admin";
import { Resources } from "../resources";

export function TeamsFilter(props: ResourceComponentProps) {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="type"
        reference={Resources.Sports}
        label="Select sport"
        alwaysOn
      >
        <SelectInput source="type" allowEmpty={false} />
      </ReferenceInput>

      <TextInput label="Search" source="q" alwaysOn />
    </Filter>
  );
}
