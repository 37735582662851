import React from "react";
import { Datagrid, TextField, List, EditButton } from "react-admin";

export const ProjectList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />

      <EditButton />
    </Datagrid>
  </List>
);
