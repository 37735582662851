import React, { useContext } from "react";
import {
  Box,
  Button,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Publish as PublishIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { formatDateTime } from "../../common/format";
import { ModeratorContext } from "../useModerator";
import { IComment, IModeratorComment, IUserComment } from "../../common/types";

interface Props {
  data: IComment;
  canPush: boolean;
  divider: boolean;
}
export function MessageListItem(props: Props) {
  switch (props.data.type) {
    case "ModeratorComment":
      return (
        <ModeratorComment {...props} data={props.data as IModeratorComment} />
      );
    case "UserComment":
      return <UserComment {...props} data={props.data as IUserComment} />;

    default:
      throw new Error("invalid typeF");
  }
}

const useStyles = makeStyles(() => ({
  published: {
    fontSize: 10,
    color: "#aaa",
  },
  title: {
    fontSize: 12,
  },
  text: (data) => ({
    // @ts-ignore
    textDecoration: data.removed ? "line-through" : "",
  }),
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

function Likes({ data }: { data: IUserComment }) {
  return (
    <Box display="flex" justifyContent="">
      <Box pr={1}>
        <ThumbUpIcon style={{ fontSize: "1rem" }} />
      </Box>
      <Box pr={3}>
        <Typography>{data.likes} </Typography>
      </Box>
      <Box pr={1}>
        <ThumbDownIcon style={{ fontSize: "1rem" }} />
      </Box>
      <Box>
        <Typography> {data.dislikes}</Typography>
      </Box>
    </Box>
  );
}

function UserComment({
  data,
  canPush,
  divider,
}: Props & { data: IUserComment }) {
  const { api } = useContext(ModeratorContext);
  const classes = useStyles(data);

  return (
    <ListItem className={classes.listItem} divider={divider}>
      <Typography className={classes.published}>
        Published: {formatDateTime(data.time)}
      </Typography>
      <Typography className={classes.title}>
        {data.name} ({data.email})
      </Typography>

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box mb={1}>
            <Typography className={classes.text}>{data.text}</Typography>
          </Box>
          <Likes data={data} />

          {data.pushed && <Typography>pushed</Typography>}
          <Box>
            <Button
              size="small"
              startIcon={<PublishIcon />}
              disabled={!canPush}
              onClick={() => api.pushComment(data.id)}
            >
              Push
            </Button>
            <Button
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => api.banUser(data.userId)}
            >
              Ban
            </Button>
            {!data.removed && (
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => api.deleteComment(data.id)}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}

function ModeratorComment({
  data,
  canPush,
  divider,
}: Props & { data: IModeratorComment }) {
  const classes = useStyles(data);
  const { api } = useContext(ModeratorContext);

  return (
    <ListItem className={classes.listItem} divider={divider}>
      <Typography className={classes.published}>
        Published: {formatDateTime(data.time)}
      </Typography>
      <Typography className={classes.title}>moderator</Typography>

      <Box mb={1}>
        <Typography className={classes.text}>{data.text}</Typography>
      </Box>

      {data.pushed && <Typography>pushed</Typography>}

      <Box>
        <Button
          size="small"
          startIcon={<PublishIcon />}
          disabled={!canPush}
          onClick={() => api.pushFeed(data.id)}
        >
          Push
        </Button>
        {!data.removed && (
          <Button
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => api.deleteFeed(data.id)}
          >
            Remove
          </Button>
        )}
      </Box>
    </ListItem>
  );
}
