import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Button,
  Link,
  TextFieldProps,
  ReferenceField,
} from "react-admin";
import { roles } from "../roles";
import { DateTimeField } from "../common/components/DateTimeField";
import { SummaryButton as SummaryButtonUI } from "../common/components/SummaryButton";
import BrushIcon from "@material-ui/icons/Brush";

import { EventsFilter } from "./EventsFilter";

function SummaryButton(props: any) {
  const record = props.record;
  if (!record || !record.rooms.length) {
    return null;
  }
  const endTime = +new Date(record["endTimeUtc"]);
  const roomId = record.rooms[0].id;

  if (+new Date() > endTime) {
    return <SummaryButtonUI roomId={roomId} variant="text" size="small" />;
  }

  return null;
}

function ModeratorButton(props: any) {
  const stopPropagation = (e: any) => e.stopPropagation();
  const record = props.record;

  const startDate = +new Date(record["startTimeUtc"]);
  const endDate = +new Date(record["endTimeUtc"]);
  const now = +new Date();

  if (startDate < now && now < endDate) {
    return (
      <Button
        {...props}
        component={Link}
        label="Moderate"
        to={`moderator/${record.id}`}
        onClick={stopPropagation}
      >
        <BrushIcon />
      </Button>
    );
  }

  return null;
}

function TeamsField({ record }: TextFieldProps) {
  if (!record) {
    return null;
  }

  const teams: Array<{ name: string }> = record.teams;
  if (teams?.length <= 1) {
    return null;
  }

  return (
    <span>
      {teams[0].name} - {teams[1].name}
    </span>
  );
}

export const EventList = (props: any) => {
  console.log(props);
  const role = props.permissions?.role;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<EventsFilter />}
      filterDefaultValues={{ status: "active" }}
    >
      <Datagrid>
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="eventType" />

        {role === roles.Admin && (
          <ReferenceField source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TeamsField source="teams" />

        <DateTimeField source="startTimeUtc" />
        <DateTimeField source="endTimeUtc" />

        <EditButton />
        <SummaryButton />
        <ModeratorButton />
      </Datagrid>
    </List>
  );
};
