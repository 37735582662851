import React, { useEffect } from "react";
import { HttpError, useLogout } from "react-admin";

type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export function useFetch<
  TFn extends (...args: any) => any,
  TData = Unwrap<ReturnType<TFn>>,
  TParams = Parameters<TFn>
>(
  fetchFn: TFn,
  ...params: TParams[]
): { data: TData; loading: boolean; error: any; refetch: () => void } {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const [error, setError] = React.useState(null);
  const [time, setTime] = React.useState(+new Date());
  const logout = useLogout();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchFn.apply(null, params);

        setData(response);
      } catch (error) {
        const httpError = error as HttpError;
        if (httpError.status === 401) {
          logout();
          return;
        }
        console.error(error);
        setError(error);
      }

      setLoading(false);
    };

    fetchData();
  }, [time]);

  function refetch() {
    setTime(+new Date());
  }

  return { loading, data, error, refetch };
}
