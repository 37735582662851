import {
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import React from "react";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";
import { generateSummary, getSummary } from "../common/api";
import { useFetch } from "../common/hooks/useFetch";

const useItemStyle = makeStyles({
  card: {
    padding: "15px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "40px",
    fontSize: "0.75rem",
  },
  value: {
    display: "block",
    textAlign: "center",
    fontSize: "2rem",
  },
});

function Item({ title, value }: { title: string; value: string | number }) {
  const classes = useItemStyle();

  return (
    <Grid item xs={4} sm={2}>
      <Card>
        <CardContent className={classes.card}>
          <div className={classes.title}>
            <span>{title}</span>
          </div>
          <strong className={classes.value}>{value}</strong>
        </CardContent>
      </Card>
    </Grid>
  );
}

const useStyle = makeStyles({
  avatar: {
    objectFit: "contain",
  },
  winner: {
    background: "#ffc107",
  },
});

export function SummaryView() {
  const classes = useStyle();
  const { id: roomId } = useParams<{ id: string }>();
  const { loading, data, refetch } = useFetch(getSummary, roomId);

  const achievements = data?.achivements || [];
  const scoreboard = data?.leaderBoard || [];

  async function handleGeneration() {
    await generateSummary(roomId);
    refetch();
  }

  if (loading) {
    return <div>loadin</div>;
  }
  return (
    <Container>
      <Title title="Summary" />
      <h1>Summary</h1>

      {!data && (
        <>
          <Box mb={1}>
            <Typography variant="body1">
              The summary has not yet been generated...
            </Typography>
          </Box>
          <Button variant="outlined" color="primary" onClick={handleGeneration}>
            Generate summary
          </Button>
        </>
      )}

      {data && (
        <>
          <Grid container spacing={1}>
            <Item title="Total users" value={data.totalUsers} />
            <Item title="Total polls" value={data.totalPolls} />
            <Item title="Total poll answers" value={data.totalPollAnswers} />
            <Item
              title="Total moderator feeds"
              value={data.totalModeratorFeeds}
            />
            <Item title="Likes" value={data.totalLikes} />
            <Item title="Dislikes" value={data.totalDislikes} />
          </Grid>

          <h1>Scoreboard</h1>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scoreboard.map((row) => (
                  <TableRow
                    key={row.name}
                    className={row.position === 1 ? classes.winner : undefined}
                  >
                    <TableCell>{row.position}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <h1>Achievements</h1>
          <List>
            {achievements.map((x) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    classes={{
                      img: classes.avatar,
                    }}
                    variant="square"
                    alt={x.text}
                    src={x.iconUrl}
                  />
                </ListItemAvatar>
                <ListItemText primary={x.title} secondary={x.name} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Container>
  );
}
