import React, { ReactElement } from "react";
import { Edit, EditProps, SaveButton, SimpleForm, Toolbar } from "react-admin";

const MyEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" />
  </Toolbar>
);

type Props = EditProps & { children: ReactElement };

export function MyEdit(props: Props) {
  const { children, ...rest } = props;

  return (
    <Edit {...rest}>
      <SimpleForm variant="outlined" toolbar={<MyEditToolbar />}>
        {children}
      </SimpleForm>
    </Edit>
  );
}
