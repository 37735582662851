import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  useGetIdentity
} from "react-admin";
import { Dialog, DialogTitle } from "@material-ui/core";
import { roles } from "../roles";

export function InviteDialog(props: any) {
  const { open, role, onCloseDialog } = props;
  const [invitationRoles, setInvitationRoles] = useState<any>([]);
  const { identity } = useGetIdentity();
  const [projectIds, setProjectIds] = useState<any>([]);
  const dp = useDataProvider();

  useEffect(() => {
    if (!dp || !identity || !role) {
      return;
    }
    // TODO: implement filter server-side?
    dp.getList("roles", {
      filter: {},
      pagination: { perPage: 10, page: 1 },
      sort: { field: "Name", order: "asc" },
    }).then(({ data }) => {
      setInvitationRoles(
        data.filter((r) => [roles.Manager, roles.Moderator].includes(r.name))
      );
    });

    if (role === roles.Admin) {
      dp.getList("projects", {
        filter: {},
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "asc" },
      }).then(({ data }) => {
        setProjectIds(data.map(({ id }) => id));
      });
    } else if (identity) {
      dp.getOne("users", { id: identity.id }).then(({ data }) => {
        setProjectIds(data.projectIds);
      });
    }
  }, [dp, identity, role]);

  const formRedirect = () => {
    onCloseDialog();
    return "/users";
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>Invite a new user</DialogTitle>
      <Create basePath="/invitations" resource="invitations" title=" ">
        <SimpleForm redirect={formRedirect}>
          <TextInput source="Email" type="email" validate={required()} />
          <SelectInput
            source="RoleId"
            choices={invitationRoles}
            validate={required()}
          />
          {projectIds.length > 0 && (
            <ReferenceInput
              source="ProjectId"
              reference="projects"
              filter={{ Id: projectIds }}
            >
              <SelectInput label="Project" />
            </ReferenceInput>
          )}
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
