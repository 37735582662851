import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { IEvent } from "../types";
interface IProps {
  data: IEvent;
}
export function EventDetails({ data }: IProps) {
  if (!data) {
    return null;
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Event type</TableCell>
          <TableCell>Teams</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            {data.title}
          </TableCell>
          <TableCell>{data.description}</TableCell>
          <TableCell>{data.eventType}</TableCell>
          <TableCell>
            {data.teams.length === 2 &&
              `${data.teams[0].name} - ${data.teams[1].name}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
