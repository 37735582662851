import {
  Avatar,
  Button,
  Card,
  TextField,
  ThemeProvider,
  Snackbar,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LockIcon from "@material-ui/icons/Lock";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { useLogin } from "react-admin";
import { theme } from "../theme";
import {
  usePunditStyles,
  useLoginStyles,
  useLoginFormStyles,
} from "../common/styles/loginStyles";

interface FormData {
  username: string;
  password: string;
}

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

type SnackbarMessageType = "success" | "error" | "warning" | "info";

export const LoginPage = () => {
  const punditClasses = usePunditStyles();
  const loginClasses = useLoginStyles(theme);
  const loginFormClasses = useLoginFormStyles(theme);
  const login = useLogin();

  // for some reason, React Admin's `notify` doesn't work here
  // not its original hook, not dispatch(showNotification(...)) either
  // so we show the Snackbar with Alert manually
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [
    snackbarMessageType,
    setSnackbarMessageType,
  ] = useState<SnackbarMessageType>("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpened(false);
    setSnackbarMessage("");
  };

  const showSnackbar = (message: string, type: SnackbarMessageType) => {
    setSnackbarOpened(true);
    setSnackbarMessageType(type);
    setSnackbarMessage(message);
  };

  const validate = (values: FormData) => {
    const errors: any = {
      username: undefined,
      password: undefined,
    };

    if (!values.username) {
      errors.username = "Field is required";
    }
    if (!values.password) {
      errors.password = "Field is required";
    }
    return errors;
  };

  const submit = ({ username, password }: FormData) => {
    login({ username, password }).catch((error) => {
      const msg =
        typeof error === "string" ? error : "Invalid username or password.";
      showSnackbar(msg, "error");
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={loginClasses.main}>
        <Card className={loginClasses.card}>
          <div className={loginClasses.avatar}>
            <Avatar className={`${loginClasses.icon} ${punditClasses.icon}`}>
              <LockIcon />
            </Avatar>
          </div>
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={loginFormClasses.form}>
                  <div className={loginFormClasses.input}>
                    <Field
                      id="username"
                      name="username"
                      component={Input}
                      label="Username"
                    />
                  </div>
                  <div className={loginFormClasses.input}>
                    <Field
                      id="password"
                      name="password"
                      component={Input}
                      label="Password"
                      type="password"
                    />
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={loginFormClasses.button}
                  >
                    Log in
                  </Button>
                  <Link
                    href="#/lost-password"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Forgot your password? Click here
                  </Link>
                </div>
              </form>
            )}
          />
        </Card>
      </div>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessageType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
