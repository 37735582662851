import React from "react";
import { Datagrid, List, TextField } from "react-admin";

export const RoleList = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="name" sortable={false} />
    </Datagrid>
  </List>
);
