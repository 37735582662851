import React from "react";
import { required, TextInput } from "react-admin";
import { MyEdit } from "../common/components/MyEdit";

export function ProjectEdit(props: any) {
  return (
    <MyEdit {...props}>
      <TextInput source="name" fullWidth validate={required()} />
    </MyEdit>
  );
}
