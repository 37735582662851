import React from "react";
import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
} from "react-admin";
import { MyEdit } from "../common/components/MyEdit";
import { roles } from "../roles";

export function UserEdit(props: any) {
  const role = props.permissions?.role;

  return (
    <MyEdit {...props}>
      <TextInput source="userName" fullWidth disabled />
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="email" fullWidth validate={required()} />

      <ReferenceInput source="roleId" reference="roles">
        <SelectInput source="name" />
      </ReferenceInput>

      {role === roles.Admin && (
        <ReferenceArrayInput source="projectIds" reference="projects">
          <CheckboxGroupInput optionText="name" />
        </ReferenceArrayInput>
      )}
    </MyEdit>
  );
}
