import React, { useContext } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { IBannedUser } from "../../common/types";
import { ModeratorContext } from "../useModerator";
import { CardHeader } from "./CardHeader";

interface IProps {
  roomId: string;
  data: IBannedUser[];
}

export function BannedUsers({ roomId, data }: IProps) {
  const { api } = useContext(ModeratorContext);

  function renderTable() {
    return (
      <table>
        <tbody>
          {data.map((x) => (
            <tr key={x.id}>
              <th>{x.name}</th>
              <td>{x.email}</td>
              <td>
                <Button variant="contained" onClick={() => api.unbanUser(x.id)}>
                  Unban
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <Card>
      <CardHeader title="Banned users" />
      <CardContent>{data.length ? renderTable() : "none"}</CardContent>
    </Card>
  );
}
