import * as React from "react";
import { Route } from "react-router-dom";
import { Admin, ListGuesser, Resource, EditGuesser } from "react-admin";

import EqualizerIcon from "@material-ui/icons/Equalizer";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SportsIcon from "@material-ui/icons/Sports";
import PeopleIcon from "@material-ui/icons/People";
import LineWeightIcon from "@material-ui/icons/LineWeight";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import { i18nProvider } from "./providers/i18nProvider";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import { theme } from "./theme";
import { Resources } from "./resources";
import { EventCreate, EventEdit, EventList } from "./events";
import { BadgeList, BadgeEdit } from "./badges";
import { ModeratorView } from "./moderator";
import { TeamCreate, TeamEdit, TeamList } from "./teams";
import { SportList } from "./sports";
import { SummaryView } from "./summary";
import { UserList, UserEdit } from "./users";
import { RoleList } from "./roles";
import { ProjectList, ProjectCreate, ProjectEdit } from "./projects";
import { MyLayout } from "./common/components/MyLayout";
import { LoginPage } from "./login/LoginPage";
import Dashboard from "./Dashboard";
import { apiUrl } from "./conf";
import { RegistrationPage } from "./registration/RegistrationPage";
import { LostPasswordPage, ResetPasswordPage } from "./lostPassword";

const knownResources = [
  <Resource
    name={Resources.Events}
    list={EventList}
    create={EventCreate}
    edit={EventEdit}
    icon={EqualizerIcon}
  />,
  <Resource
    name={Resources.Badges}
    list={BadgeList}
    edit={BadgeEdit}
    icon={EmojiEventsIcon}
  />,
  <Resource
    name={Resources.Teams}
    list={TeamList}
    edit={TeamEdit}
    create={TeamCreate}
    icon={PeopleOutlineIcon}
  />,
  <Resource name={Resources.Sports} list={SportList} icon={SportsIcon} />,
  <Resource
    name={Resources.Users}
    list={UserList}
    edit={UserEdit}
    icon={PeopleIcon}
  />,
  <Resource name={Resources.Roles} list={RoleList} icon={LineWeightIcon} />,
  <Resource
    name={Resources.Projects}
    list={ProjectList}
    create={ProjectCreate}
    edit={ProjectEdit}
    icon={LineWeightIcon}
  />,
  <Resource name={Resources.Invitations} />
];

const fetchResources = async (permissions: { role: string }) => {
  // get permissions
  console.log("permissions", permissions);

  return Promise.resolve(knownResources);
};

const customRoutes = [
  <Route path="/moderator/:id" component={ModeratorView} />,
  <Route path="/moderator" component={ModeratorView} />,
  <Route path="/summary/:id" component={SummaryView} />,
  <Route path="/registration/:hash" component={RegistrationPage} noLayout />,
  <Route path="/lost-password" component={LostPasswordPage} noLayout />,
  <Route path="/reset-password" component={ResetPasswordPage} noLayout />
];

const App = () => (
  <MuiPickersUtilsProvider utils={LuxonUtils}>
    <Admin
      customRoutes={customRoutes}
      dataProvider={dataProvider(apiUrl + "/admin_api")}
      authProvider={authProvider(apiUrl)}
      i18nProvider={i18nProvider}
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
      dashboard={Dashboard}
      catchAll={() => <div>asd</div>}
    >
      {fetchResources}
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
