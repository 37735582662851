import React from "react";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  minLength,
} from "react-admin";
import { MyCreate } from "../common/components/MyCreate";
import { useForm, useFormState } from "react-final-form";
import { Resources } from "../resources";
import { Published } from "./components/Published";
import { roles } from "../roles";

function TeamsSelect(props: any) {
  const { change } = useForm();
  const { values } = useFormState();

  const resetTeamSelection = (e: any) => {
    change("teams", []);
  };

  return (
    <>
      <ReferenceInput
        {...props}
        source="eventType"
        reference={Resources.Sports}
        fullWidth
        onChange={resetTeamSelection}
      >
        <SelectInput source="name" validate={required()} />
      </ReferenceInput>

      <ArrayInput
        resource={Resources.Events}
        source="teams"
        variant="outlined"
        validate={minLength(2)}
      >
        <SimpleFormIterator>
          <ReferenceInput
            label="resources.events.team"
            source="id"
            reference={Resources.Teams}
            filter={{ type: values.eventType }}
          >
            <SelectInput source="name" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
export function EventCreate(props: any) {
  const role = props.permissions?.role;

  return (
    <MyCreate {...props}>
      <TextInput source="title" validate={required()} fullWidth />
      <TextInput
        source="description"
        validate={required()}
        fullWidth
        multiline
        rows="3"
      />

      <TextInput source="rooms[0].channel" validate={required()} fullWidth />

      <SelectInput
        source="rooms[0].locale"
        choices={[
          { id: "en-US", name: "en-US" },
          { id: "no-NO", name: "no-NO" },
        ]}
        validate={required()}
      />

      {role === roles.Admin && (
        <ReferenceInput
          source="projectId"
          reference="projects"
          validate={required()}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      )}

      <Published />
      <TeamsSelect />
    </MyCreate>
  );
}
