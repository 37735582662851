import { DateTime } from "luxon";

export function formatDateTime(date: string | Date) {
  const dateTime =
    typeof date === "string"
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date);

  return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
}
